<template>
  <router-view v-if="user" :user.sync="user" />
  <span v-else />
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';

export default {
  data: () => ({ user: null }),
  beforeRouteEnter (to, from, next) {
    const id = to.params?.id;
    if (!id) {
      return next(from);
    }

    $api.fetchUser(id)
      .then(res => res.data)
      .then(data => {
        if (!data) {
          return next(from);
        }

        next(vm => {
          vm.user = data;
        });
      })
      .catch(() => next(from))
    ;
  },
};

</script>
